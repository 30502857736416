import { app } from "@/main.js";

export function isSafariMobile() {
  const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/); //eslint-disable-line
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const iPadOS =
    /Macintosh/.test(navigator.userAgent) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2;
  if (isSafari && (iOS || iPadOS)) {
    return true;
  }
  return false;
}

export async function copyToClipboard(value) {
  try {
    await navigator.clipboard.writeText(value);
    this.$root.showSuccess(
      "<strong>in Zwischenablage kopiert:</strong><br>" + value
    );
  } catch (err) {
    this.$root.showError("Fehler beim Kopieren in die Zwischenablage");
  }
}

// iban-check https://stackoverflow.com/questions/21928083/iban-validation-check
function mod97(string) {
  var checksum = string.slice(0, 2),
    fragment;
  for (var offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}

export function isValidIBANNumber(input) {
  var CODE_LENGTHS = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 22,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
    AL: 28,
    BY: 28,
    EG: 29,
    GE: 22,
    IQ: 23,
    LC: 32,
    SC: 31,
    ST: 25,
    SV: 28,
    TL: 23,
    UA: 29,
    VA: 22,
    VG: 24,
    XK: 20,
  };
  var iban = String(input)
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, ""), // keep only alphanumeric characters
    code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
    digits;
  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false;
  }
  // rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return letter.charCodeAt(0) - 55;
  });
  // final check
  return mod97(digits) == 1;
}

export function getEmailClient() {
  return localStorage.getItem("emailClient") || "mailto";
}

export function setEmailClient(emailClient) {
  localStorage.setItem("emailClient", emailClient);
  app.apiPost({
    resource: "app/settings",
    data: { key: "emailClient", value: emailClient },
  });
}

export function mailto(value) {
  if (getEmailClient() == "outlook") {
    return "https://outlook.office365.com/owa/?rru=compose&to=" + value;
  }
  return "mailto:" + value;
}

export function sortLookups(lookups) {
  if (!lookups) {
    return lookups;
  }
  return lookups.slice().sort((a, b) => {
    return compareLookups(a, b);
  });
}

export function compareLookups(a, b) {
  if (!a) {
    return 1;
  }
  if (!b) {
    return -1;
  }
  return a.code.localeCompare(b.code, "de-CH");
}

export function searchLookup(lookup, search) {
  if (!search) {
    return false;
  }
  if (!lookup) {
    return false;
  }
  const s = search.toLowerCase();
  const code = lookup.code.toLowerCase();
  const description = lookup.description.toLowerCase();
  return code.includes(s) || description.includes(s);
}
